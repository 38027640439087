import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from 'material-ui-pickers';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import {
  putUserRequest,
  putTicketRequestRequest,
  getDetailsTicketRequestRequest,
} from '../../../../../../config/actions.config';
import { formatDatePicker } from '../../../../../../config/services.config';
import IntlMessages from '../../../../../../util/IntlMessages';

class GuestDetailsTabsContainerInfosPerso extends Component {
  constructor() {
    super();

    this.state = {
      activeStep: 0,
      showAccessZones: false,
      userLoad: false,
      ticketRequestLoad: false,
      user: null,
      ticketRequestType: null,
      reason: null,
    };
    this.user = null;
  }

  componentDidMount() {
    this.user = {
      id: this.props.user.id,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      gender: this.props.user.gender,
      email: this.props.user.email,
      birthday: this.props.user.birthday,
      phonenumber: this.props.user.phonenumber,
    };
    this.setState({
      user: this.user,
      reason: this.props.reason,
      ticketRequestType: this.props.ticketRequests.details.type,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      if (this.props.user) {
        this.user = {
          id: this.props.user.id,
          firstName: this.props.user.firstName,
          lastName: this.props.user.lastName,
          gender: this.props.user.gender,
          email: this.props.user.email,
          birthday: this.props.user.birthday,
          phonenumber: this.props.user.phonenumber,
        };

        this.setState({
          user: this.user,
          ticketRequestType: this.props.ticketRequests.details.type,
          reason: this.props.reason,
        });
      }
    }
    if (prevProps.ticketRequestUpdate !== this.props.ticketRequestUpdate) {
      this.setState({
        ticketRequestLoad: this.props.ticketRequestUpdate.loading,
      });
      if (!this.props.ticketRequestUpdate.loading) {
        this.setState({
          ticketRequestType: this.props.ticketRequestUpdate.ticketRequest.type,
          reason: this.props.ticketRequestUpdate.ticketRequest.reason,
        });
      }
    }
    if (prevProps.userUpdated !== this.props.userUpdated) {
      this.setState({ userLoad: this.props.userUpdated.loading });
    }
  }

  handleChangeUser = (name) => (event) => {
    this.user[name] = event.target.value;
    this.setState({ user: this.user });
  };

  handleChangeType = (event) => {
    this.setState({ ticketRequestType: event.target.value });
  };

  handleChangeReason = (event) => {
    this.setState({ reason: event.target.value });
  };

  handleDateBirthChange = (date) => {
    this.user.birthday = formatDatePicker(date);
    this.setState({ user: this.user });
  };

  putUser = (e) => {
    e.preventDefault();
    this.props.putUserRequest(this.state.user);
  };

  putTicketRequest = (e) => {
    e.preventDefault();
    this.props.putTicketRequestRequest(this.props.idProject, {
      id: this.props.ticketRequests.details.id,
      type: this.state.ticketRequestType,
      reason: this.state.reason,
    });
  };

  render() {
    const { user, ticketRequestType, reason } = this.state;

    const gender = [
      {
        value: 'M',
        label: 'Homme',
      },
      {
        value: 'F',
        label: 'Femme',
      },
    ];

    const requestType = [
      {
        value: 'guest',
        label: 'Guest',
      },
      {
        value: 'supplier',
        label: 'Fournisseurs / Partenaires',
      },
      {
        value: 'sponsor',
        label: 'Sponsors',
      },
      {
        value: 'artist',
        label: 'Artistes',
      },
      {
        value: 'press',
        label: 'Presse',
      },
      {
        value: 'vip',
        label: 'VIP',
      },
      {
        value: 'accredited',
        label: 'Autres',
      },
    ];
    return (
      <div className="row" style={{ paddingLeft: '15px', marginRight: '15px' }}>
        <div className="col-12">
          <form className="row" onSubmit={this.putTicketRequest}>
            <div className="col-12" style={{ marginTop: 30 + 'px' }}>
              <h3 style={{ color: 'black' }}>
                <IntlMessages id="modal.personal.tab.title.type" />
              </h3>
            </div>
            <div className="col-12">
              <hr style={{ marginBottom: 30 + 'px' }} />
            </div>
            <div className="col-4">
              <h4>
                <IntlMessages id="modal.personal.tab.title.invitation.type" />
              </h4>
              <span className="sous-texte" style={{ paddingRight: 0 }}>
                <IntlMessages id="modal.personal.tab.span.invitation.type" />
              </span>
            </div>
            <div className="col-5">
              <TextField
                id="select-tp-invitation"
                select
                disabled
                style={{ width: '100%' }}
                value={ticketRequestType}
                onChange={this.handleChangeType}
                helperText={
                  <IntlMessages id="modal.personal.tab.invitation.type.helperText" />
                }
                margin="normal"
              >
                {requestType.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-4 mt-4 textFieldLabel">
              <h4>
                <IntlMessages id="modal.personal.tab.motivation.title" />
              </h4>
            </div>
            <div className="col-5 mt-4">
              <div className="form-group">
                <TextField
                  style={{ marginTop: '3px' }}
                  id="motivation"
                  margin="normal"
                  value={reason ? reason : ''}
                  onChange={this.handleChangeReason}
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={2}
                  disabled
                />
              </div>
            </div>
            <div className="col-6" style={{ marginTop: 40 + 'px' }}>
              <h3 style={{ color: 'black' }}>
                <IntlMessages id="modal.personal.tab.personal.infos.title" />
              </h3>
            </div>
            <div className="col-6">
              <Button
                disabled
                variant="outlined"
                color="primary"
                type="submit"
                style={{ float: 'right' }}
              >
                {this.state.ticketRequestLoad ? (
                  <CircularProgress style={{ width: '80%', height: 'auto' }} />
                ) : (
                  <IntlMessages id="modal.personal.tab.save.button" />
                )}
              </Button>
            </div>
          </form>
          <form className="row" onSubmit={this.putUser}>
            <div className="col-12">
              <hr style={{ marginBottom: 10 + 'px' }} />
            </div>
            <div className="col-4 textFieldLabel">
              <h4>
                <IntlMessages id="modal.personal.tab.personal.infos.name" />
              </h4>
            </div>
            <div className="col-5">
              <div className="form-group">
                <TextField
                  style={{ marginTop: '2px' }}
                  required
                  disabled
                  type="text"
                  id="firstName"
                  margin="normal"
                  value={user ? user.firstName : ''}
                  onChange={this.handleChangeUser('firstName')}
                  fullWidth
                />
              </div>
            </div>
            <div className="col-4 textFieldLabel">
              <h4>
                <IntlMessages id="modal.personal.tab.personal.infos.fisrtName" />
              </h4>
            </div>
            <div className="col-5">
              <div className="form-group">
                <TextField
                  style={{ marginTop: '2px' }}
                  required
                  disabled
                  id="lastName"
                  type="text"
                  value={user ? user.lastName : ''}
                  onChange={this.handleChangeUser('lastName')}
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="col-4 textFieldLabel">
              <h4>
                <IntlMessages id="modal.personal.tab.personal.infos.gender" />
              </h4>
            </div>
            <div className="col-5">
              <div className="form-group">
                <TextField
                  required
                  disabled
                  style={{ marginTop: '2px', width: '100%' }}
                  id="standard-select-currency"
                  select
                  value={user ? user.gender : 'M'}
                  onChange={this.handleChangeUser('gender')}
                  margin="normal"
                >
                  {gender.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="col-4 textFieldLabel">
              <h4>
                <IntlMessages id="modal.personal.tab.personal.infos.mail" />
              </h4>
            </div>
            <div className="col-5">
              <div className="form-group">
                <TextField
                  style={{ marginTop: '1px' }}
                  required
                  disabled
                  id="email"
                  margin="normal"
                  type="email"
                  fullWidth
                  value={user ? user.email : ''}
                  onChange={this.handleChangeUser('email')}
                />
              </div>
            </div>
            <div className="col-4 textFieldLabel">
              <h4>
                <IntlMessages id="modal.personal.tab.personal.infos.birthday" />
              </h4>
            </div>
            <div className="col-5">
              <div className="form-group">
                <DatePicker
                  disabled
                  value={user ? user.birthday : null}
                  style={{ width: '100%' }}
                  clearable
                  onChange={this.handleDateBirthChange}
                  onError={console.log}
                  format="dd/MM/yyyy"
                  views={['year', 'month', 'day']}
                  openTo="year"
                  mask={[
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </div>
            </div>
            <div className="col-4 textFieldLabel" style={{ marginTop: '27px' }}>
              <h4>
                <IntlMessages id="modal.personal.tab.personal.infos.phone" />
              </h4>
            </div>
            <div className="col-5">
              <div className="form-group">
                <TextField
                  disabled
                  id="phonenumber"
                  type="tel"
                  value={user ? user.phonenumber : ''}
                  onChange={this.handleChangeUser('phonenumber')}
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: '15px' }}>
              <Button
                disabled
                variant="outlined"
                color="primary"
                type="submit"
                style={{ float: 'right' }}
              >
                {this.state.userLoad ? (
                  <CircularProgress style={{ width: '80%', height: 'auto' }} />
                ) : (
                  <IntlMessages id="modal.personal.tab.save.button" />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  userUpdated: state.sdk.user.userUpdate,
  ticketRequests: state.sdk.ticketRequests,
  ticketRequestUpdate: state.sdk.ticketRequests.ticketRequestUpdate,
});

export default connect(mapStateToProps, {
  putUserRequest,
  putTicketRequestRequest,
  getDetailsTicketRequestRequest,
})(GuestDetailsTabsContainerInfosPerso);
