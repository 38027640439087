import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GuestDetailsTabsContainerAssignment from './GuestDetailsTabsContainer/GuestDetailsTabsContainerAssignment';
import GuestDetailsTabsContainerInfosCompany from './GuestDetailsTabsContainer/GuestDetailsTabsContainerInfosCompany';
import GuestDetailsTabsContainerActivity from './GuestDetailsTabsContainer/GuestDetailsTabsContainerActivity';
import GuestDetailsTabsContainerInfosPerso from './GuestDetailsTabsContainer/GuestDetailsTabsContainerInfosPerso';
import IntlMessages from '../../../../util/IntlMessages';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class GuestDetailsTabs extends Component {
  constructor() {
    super();
    this.state = {
      value: 0,
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (event, index) => {
    this.setState({ value: index });
  };

  render() {
    const { value } = this.state;

    return (
      <div className="col-9" style={{ padding: 0, overflow: 'hidden' }}>
        <AppBar
          position="static"
          color="inherit"
          style={{
            boxShadow: 'none',
            marginTop: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
            variant="fullWidth"
          >
            <Tab
              label={<IntlMessages id="modal.tab.title.activity" />}
              id={'full-width-tab-0'}
              aria-controls={'full-width-tabpanel-0'}
            />
            <Tab
              label={<IntlMessages id="modal.tab.title.personalInfos" />}
              id={'full-width-tab-1'}
              aria-controls={'full-width-tabpanel-1'}
            />
            <Tab
              label={<IntlMessages id="modal.tab.title.companyInfos" />}
              id={'full-width-tab-2'}
              aria-controls={'full-width-tabpanel-2'}
            />
            {/* <Tab label="Attribution" /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <GuestDetailsTabsContainerActivity
            guest={this.props.guest}
            ticketRequestsDetails={this.props.ticketRequestsDetails}
            itemsTicketRequests={this.props.itemsTicketRequests}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GuestDetailsTabsContainerInfosPerso
            guest={this.props.guest}
            user={this.props.user}
            reason={this.props.ticketRequestsDetails?.reason}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GuestDetailsTabsContainerInfosCompany
            guest={this.props.guest.guest}
            post={
              this.props.ticketRequestsDetails.usercompany &&
              this.props.ticketRequestsDetails.usercompany.post
                ? this.props.ticketRequestsDetails.usercompany.post
                : ''
            }
            guestCompany={
              this.props.ticketRequestsDetails.company
            }
            userCompany={this.props.ticketRequestsDetails.company}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <GuestDetailsTabsContainerAssignment />
        </TabPanel>
      </div>
    );
  }
}

export default GuestDetailsTabs;
