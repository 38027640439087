import React from 'react';
import Button from '@material-ui/core/Button';
import { MoreHoriz, SaveAlt, Edit } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from '../../util/IntlMessages';
import { connect } from 'react-redux';
import {
  acceptTicketRequestRequest,
  rejectTicketRequestRequest,
  submitTicketRequestRequest,
  deleteTicketRequestRequest,
  getUserDetailsRequest,
  getDetailsTicketRequestRequest,
  getItemsTicketRequestRequest,
  getTicketRequestsByProjectRequest,
  getAddressCompanyRequest,
  getUserProjectRequest,
} from '../../config/actions.config';
import ModalRejectTicketRequest from '../Guest/Modal/ModalRejectTicketRequest';
import './buttons.css';

class ButtonsActionsGuestsList extends React.Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      openModalViewGuest: false,
      loadingUser: false,
      updateTable: false,
      rejectTicketRequestModal: false,
    };

    this.updateTable = false;
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  submitTicketRequests = () => {
    this.setState({ anchorEl: null });
    this.props.submitTicketRequestRequest(
      this.props.ticketRequest.ticketRequestsId
    );
    this.updateTable = true;
  };

  acceptTicketRequests = () => {
    this.setState({ anchorEl: null });
    this.props.acceptTicketRequestRequest(
      this.props.ticketRequest.ticketRequestsId
    );
    this.updateTable = true;
  };

  rejectTicketRequests = (motif) => {
    this.setState({ anchorEl: null });
    this.props.rejectTicketRequestRequest(
      this.props.ticketRequest.ticketRequestsId,
      motif
    );
    this.updateTable = true;
  };

  deleteTicketRequests = () => {
    this.setState({ anchorEl: null });
    this.props.deleteTicketRequestRequest(
      this.props.ticketRequest.ticketRequestsId
    );
    this.props.getTicketRequestsByProjectRequest(
      this.props.idProject,
      this.props.ticketRequest.statusFilter,
      this.props.ticketRequest.typeFilter
    );
    this.updateTable = true;
  };

  changeStatusOpenModal = (open) => {
    this.props.openModal(open, this.props.ticketRequest);
    this.setState({ openModalViewGuest: open });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ticketRequests !== this.props.ticketRequests) {
      this.setState({ loadingUser: this.props.ticketRequests.loading });
    }
    if (
      prevProps.ticketRequest.ticketRequestsId !==
      this.props.ticketRequest.ticketRequestsId
    ) {
      if (this.props.ticketRequest.company) {
        this.props.getAddressCompanyRequest(
          this.props.ticketRequest.company.id
        );
      }
    }
    if (prevProps.ticketRequestAccepted !== this.props.ticketRequestAccepted) {
      if (
        this.props.ticketRequestAccepted.error === false &&
        this.props.ticketRequest.id === this.props.ticketRequestAccepted.id
      ) {
        // this.props.getTicketRequestsByProjectRequest(this.props.idProject, this.props.ticketRequest.statusFilter, this.props.ticketRequest.typeFilter);
      }
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const status = this.props.ticketRequest.assignmentStatus.props.id;
    const { anchorEl } = this.state;
    if (this.updateTable && !this.props.ticketRequests.loading) {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        this.props.ticketRequest.statusFilter,
        this.props.ticketRequest.typeFilter
      );
      this.updateTable = false;
    }
    let urlTicketsDownloads = null;
    if (this.props.ticketRequest.ticketDownloads) {
      urlTicketsDownloads = this.props.ticketRequest.ticketDownloads[0]
        ? this.props.ticketRequest.ticketDownloads[0].url
        : null;
    }

    return (
      <div align="right">
        {urlTicketsDownloads && urlTicketsDownloads !== null && (
          <a download href={urlTicketsDownloads}>
            <Button
              aria-owns={anchorEl ? 'simple-menu' : null}
              aria-haspopup="true"
              className="btn btn-primary action-button"
            >
              <SaveAlt className={'action-button-icon'} />
            </Button>
          </a>
        )}

        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          className="btn btn-primary action-button"
          onClick={() => this.changeStatusOpenModal(true)}
        >
          <Edit className={'action-button-icon'} />
        </Button>

        {status !== 'table.column.status.accepted' &&
          status !== 'table.column.status.refused' && (
            <>
              <Button
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                className="btn btn-primary action-button"
                onClick={this.handleClick}
              >
                <MoreHoriz className={'action-button-icon'} />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                {status === 'table.column.status.draft' && (
                  <MenuItem onClick={this.submitTicketRequests}>
                    <IntlMessages id="menuItem.status.accepting" />
                  </MenuItem>
                )}
                {status === 'table.column.status.validating' && (
                  <>
                    <MenuItem onClick={this.acceptTicketRequests}>
                      <IntlMessages id="menuItem.status.accepted" />
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.setState({ rejectTicketRequestModal: true })
                      }
                    >
                      <IntlMessages id="menuItem.status.refused" />
                    </MenuItem>
                  </>
                )}

                {status === 'En rédaction' && (
                  <MenuItem onClick={this.deleteTicketRequests}>
                    Supprimer
                  </MenuItem>
                )}
              </Menu>
            </>
          )}

        <ModalRejectTicketRequest
          open={this.state.rejectTicketRequestModal}
          rejectTicketRequest={this.rejectTicketRequests}
          onClose={() =>
            this.setState({ rejectTicketRequestModal: false, anchorEl: null })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketRequests: state.sdk.ticketRequests,
  idProject: state.sdk.user.currentProject.idCurrentProject,
  addressCompany: state.sdk.companies.addressCompany,
  ticketRequestAccepted: state.sdk.ticketRequests.ticketRequestAccepted,
});

export default connect(mapStateToProps, {
  acceptTicketRequestRequest,
  rejectTicketRequestRequest,
  deleteTicketRequestRequest,
  getUserDetailsRequest,
  getDetailsTicketRequestRequest,
  getItemsTicketRequestRequest,
  getTicketRequestsByProjectRequest,
  getAddressCompanyRequest,
  getUserProjectRequest,
  submitTicketRequestRequest,
})(ButtonsActionsGuestsList);
