import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class DetailsRowTabGuest extends Component {
  constructor() {
    super();
    this.products = [];
  }

  render() {
    let products = '';

    this.products = this.props.products ? this.props.products : [];

    if (this.products.length > 0) {
      const newList = {};
      this.props.products.forEach((data) => {
        if (!Object.prototype.hasOwnProperty.call(newList, data.idProduct)) {
          newList[data.idProduct] = {
            idProduct: null,
            quantity: null,
            product: null,
            id: null,
          };
          newList[data.idProduct].quantity = data.quantity - 1;
          newList[data.idProduct].idProduct = data.idProduct;
          newList[data.idProduct].product = data.product;
          newList[data.idProduct].id = data.id;
        }
        newList[data.idProduct].quantity++;
      });
      this.products = [];
      this.props.products.forEach((data, i) => {
        if (Object.keys(newList).length > i) {
          this.products[i] = newList[data.idProduct];
        }
      });

      if (this.products !== undefined) {
        if (
          typeof this.products !== 'undefined' &&
          this.products instanceof Array
        ) {
          products = this.products.map((product, i) => (
            <TableRow key={i}>
              <TableCell>{product.product ? product.product : '--'}</TableCell>
              <TableCell>
                {product.quantity ? product.quantity : '--'}
              </TableCell>
            </TableRow>
          ));
        } else {
          products = (
            <TableRow key={this.products[0].idProduct}>
              <TableCell>
                {this.props.products[0].product
                  ? this.props.products[0].product
                  : '--'}
              </TableCell>
              <TableCell>
                {this.props.products[0].quantity
                  ? this.props.products[0].quantity
                  : '--'}
              </TableCell>
            </TableRow>
          );
        }
      }
    }

    if (!(this.products instanceof Array)) {
      products = (
        <TableRow key={this.products.idProduct}>
          <TableCell>
            {this.props.products.product ? this.props.products.product : '--'}
          </TableCell>
          <TableCell>
            {this.props.products.quantity ? this.props.products.quantity : '--'}
          </TableCell>
        </TableRow>
      );
    }

    return (
      <Table className="rowDetails">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Type de tickets</b>
            </TableCell>
            <TableCell>
              <b>Qté allouées</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{products}</TableBody>
      </Table>
    );
  }
}
export default DetailsRowTabGuest;
