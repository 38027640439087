import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import IntlMessages from './../../../../../util/IntlMessages';
import {
  RowDetailState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  SearchState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ReloadIcon from '@material-ui/icons/Replay';
import IconButton from '@material-ui/core/IconButton';
import {
  getTicketRequestsByProjectRequest,
  getItemsTicketRequestRequest,
  getDetailsTicketRequestRequest,
  getAddressCompanyRequest,
  getUserProjectRequest,
} from '../../../../../config/actions.config';
import ContainerHeader from '../../../../../components/ContainerHeader';
import ButtonsActionsGuestsList from '../../../../../components/ButtonsActions/ButtonsActionsGuestsList';
import { formatDate } from '../../../../../config/services.config';
import Button from '@material-ui/core/Button';
import IconWithTextCard from '../../../../../components/Card/IconWithTextCard';
import ModalViewGuest from '../../../../../components/Guest/Modal/ModalViewGuest';
import DetailsRowTabGuest from '../../../../../components/Table/TableDetailsGuest';
import { Alert } from 'reactstrap';
import { Loading } from '../../../../../config/components.config';
import { checkReduxResponse } from 'private_live_sdk_react';

const CellGuest = (cell) => {
  const content =
    cell.column.name === 'button' ? (
      <ButtonsActionsGuestsList
        ticketRequest={cell.row}
        openModal={cell.openModal}
      />
    ) : (
      cell.value
    );

  return <Table.Cell style={cell.style}>{content}</Table.Cell>;
};

const tableColumnExtensions = [
  { columnName: 'button', align: 'right', width: 100 },
];

class GuestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListLoading: true,
      isListItemLoading: false,
      columns: [
        {
          name: 'guestName',
          title: <IntlMessages id="table.column.name.guestName" />,
        },
        {
          name: 'guestType',
          title: <IntlMessages id="table.column.name.type" />,
        },
        {
          name: 'companyName',
          title: <IntlMessages id="table.column.name.entreprise" />,
        },
        {
          name: 'guestEmail',
          title: <IntlMessages id="table.column.name.mail" />,
        },
        {
          name: 'lastUpdate',
          title: <IntlMessages id="table.column.name.lastUpdate" />,
        },
        {
          name: 'assignmentStatus',
          title: <IntlMessages id="table.column.name.status" />,
        },
        {
          name: 'button',
          title: <IntlMessages id="table.column.name.actions" />,
        },
      ],
      columnHidden: ['guestName'],
      ticketRequestsFilter: 'Toutes les demandes',
      ticketRequestsCurrentFilter: 'all',
      ticketRequestsCurrentFilterType: 'all',
      openModalViewGuest: false,
      currentTicketRequest: null,
    };
    this.statsTicketRequest = {
      accepted: 0,
      review: 0,
      rejected: 0,
      draft: 0,
    };
  }

  componentDidMount() {
    if (this.props.idProject) {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        this.state.ticketRequestsCurrentFilter,
        this.state.ticketRequestsCurrentFilterType
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idProject !== this.props.idProject) {
      if (this.props.idProject) {
        this.props.getTicketRequestsByProjectRequest(
          this.props.idProject,
          this.state.ticketRequestsCurrentFilter,
          this.state.ticketRequestsCurrentFilterType
        );
      }
    }

    if (prevProps.ticketRequestsList !== this.props.ticketRequestsList) {
      if (!this.props.ticketRequests.loading) {
        this.setState({ isListLoading: false, isListItemLoading: false });
      }
    }

    if (prevProps.ticketRequestAccepted !== this.props.ticketRequestAccepted) {
      if (
        checkReduxResponse(this.props.ticketRequestAccepted, 'ticketRequest')
      ) {
        this.props.getTicketRequestsByProjectRequest(
          this.props.idProject,
          this.state.ticketRequestsCurrentFilter,
          this.state.ticketRequestsCurrentFilterType
        );
      }
    }

    if (prevProps.ticketRequestRefused !== this.props.ticketRequestRefused) {
      if (
        checkReduxResponse(this.props.ticketRequestRefused, 'ticketRequest')
      ) {
        this.props.getTicketRequestsByProjectRequest(
          this.props.idProject,
          this.state.ticketRequestsCurrentFilter,
          this.state.ticketRequestsCurrentFilterType
        );
      }
    }
  }

  handleChangeTicketRequests = (event) => {
    if (event.target.value === 'Toutes les demandes') {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        'all',
        this.state.ticketRequestsCurrentFilterType
      );
      this.setState({
        ticketRequestsFilter: event.target.value,
        ticketRequestsCurrentFilter: 'all',
      });
    } else if (event.target.value === 'En validations') {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        'review',
        this.state.ticketRequestsCurrentFilterType
      );
      this.setState({
        ticketRequestsFilter: event.target.value,
        ticketRequestsCurrentFilter: 'review',
      });
    } else if (event.target.value === 'Brouillons') {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        'draft',
        this.state.ticketRequestsCurrentFilterType
      );
      this.setState({
        ticketRequestsFilter: event.target.value,
        ticketRequestsCurrentFilter: 'draft',
      });
    } else if (event.target.value === 'Refusées') {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        'rejected',
        this.state.ticketRequestsCurrentFilterType
      );
      this.setState({
        ticketRequestsFilter: event.target.value,
        ticketRequestsCurrentFilter: 'rejected',
      });
    } else if (event.target.value === 'Acceptées') {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        'accepted',
        this.state.ticketRequestsCurrentFilterType
      );
      this.setState({
        ticketRequestsFilter: event.target.value,
        ticketRequestsCurrentFilter: 'accepted',
      });
    }
  };

  handleChangeTicketRequestsType = (event) => {
    this.props.getTicketRequestsByProjectRequest(
      this.props.idProject,
      this.state.ticketRequestsCurrentFilter,
      event.target.value
    );
    this.setState({ ticketRequestsCurrentFilterType: event.target.value });
  };

  handleOpenModalView = (open, ticketRequest) => {
    this.setState({
      openModalViewGuest: open,
      currentTicketRequest: ticketRequest,
    });
    if (open) {
      this.props.getDetailsTicketRequestRequest(ticketRequest.ticketRequestsId);
      this.props.getItemsTicketRequestRequest(ticketRequest.ticketRequestsId);
      this.props.getUserProjectRequest(
        ticketRequest.guest.id,
        this.props.idProject
      );
      if (ticketRequest.company) {
        this.props.getAddressCompanyRequest(ticketRequest.company.id);
      }
    } else {
      this.props.getTicketRequestsByProjectRequest(
        this.props.idProject,
        ticketRequest.statusFilter,
        ticketRequest.typeFilter
      );
    }
  };

  render() {
    const { locale, timezone } = this.props;
    const { columns, isListLoading, isListItemLoading } = this.state;
    const rows = [];
    if (this.props.ticketRequestsList !== null) {
      if (this.props.ticketRequestsList !== '') {
        this.statsTicketRequest = {
          accepted: 0,
          review: 0,
          rejected: 0,
          draft: 0,
        };
        this.props.ticketRequestsList.forEach((data, i) => {
          let status = '';
          let type = '';
          if (data.status === 'accepted') {
            this.statsTicketRequest.accepted++;
            status = <IntlMessages id="table.column.status.accepted" />;
          } else if (data.status === 'rejected') {
            this.statsTicketRequest.rejected++;
            status = <IntlMessages id="table.column.status.refused" />;
          } else if (data.status === 'review') {
            this.statsTicketRequest.review++;
            status = <IntlMessages id="table.column.status.validating" />;
          } else if (data.status === 'draft') {
            this.statsTicketRequest.draft++;
            status = <IntlMessages id="table.column.status.draft" />;
          }
          if (data.type === 'sponsor') {
            type = <IntlMessages id="table.column.type.sponsors" />;
          } else if (data.type === 'artist') {
            type = <IntlMessages id="table.column.type.artists" />;
          } else if (data.type === 'supplier') {
            type = <IntlMessages id="table.column.type.suppliers" />;
          } else if (data.type === 'guest') {
            type = <IntlMessages id="table.column.type.guests" />;
          } else if (data.type === 'accredited') {
            type = <IntlMessages id="table.column.type.accredited" />;
          } else if (data.type === 'press') {
            type = <IntlMessages id="table.column.type.press" />;
          } else if (data.type === 'vip') {
            type = <IntlMessages id="table.column.type.vip" />;
          }
          rows[i] = {
            ticketRequestsId: data.id,
            ticketRequest: '',
            guest: data.userProfile,
            guestName: data.userProfile.fullName,
            guestEmail: data.userProfile.email,
            guestType: type,
            companyName:
              data.usercompany && data.usercompany.company
                ? data.usercompany.company.name
                : '',
            // guestTickets: "--",
            lastUpdate:
              data.updatedAt !== null
                ? formatDate(data.updatedAt, locale, timezone)
                : formatDate(data.createdAt, locale, timezone),
            assignmentStatus: status,
            company:
              data.usercompany && data.usercompany.company
                ? data.usercompany.company
                : '',
            ticketDownloads: data.ticketDownloads,
            statusFilter: this.state.ticketRequestsCurrentFilter,
            typeFilter: this.state.ticketRequestsCurrentFilterType,
          };
        });
      }
    }

    const RowDetail = ({ row }) => {
      if (this.props.ticketRequests.items !== undefined) {
        if (!this.props.ticketRequests.error) {
          return (
            <div style={{ position: 'relative' }}>
              <DetailsRowTabGuest
                key={row.ticketRequestsId}
                products={this.props.ticketRequests.items[row.ticketRequestsId]}
              />
              {isListItemLoading && <Loading />}
            </div>
          );
        } else {
          return (
            <div align="center">
              <Alert
                className="shadow-lg"
                color="danger"
                style={{ marginTop: '15px', width: '50%' }}
              >
                <IntlMessages id="rowDetail.alert.error" />
              </Alert>
            </div>
          );
        }
      } else {
        return null;
      }
    };

    const onExpandedRowIdsChange = (expandedRowIds) => {
      if (expandedRowIds.length > 0) {
        let id = expandedRowIds[expandedRowIds.length - 1];
        this.props.getItemsTicketRequestRequest(rows[id].ticketRequestsId);
      }
    };

    return (
      <div className="app-wrapper div-wrapper-title grid">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-4 col-xl-4 col-xxl-7">
            <ContainerHeader
              match={{ path: '/app/Accréditations/Liste des accréditations' }}
              title={<IntlMessages id="containerHeader.title" />}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xl-5 col-xxl-4">
            <div style={{ marginTop: '27px', float: 'right' }}>
              <IconButton
                style={{ marginTop: '-5px' }}
                aria-label="Reload"
                onClick={() => {
                  this.setState({ isListLoading: true });

                  this.props.getTicketRequestsByProjectRequest(
                    this.props.idProject,
                    this.state.ticketRequestsCurrentFilter,
                    this.state.ticketRequestsCurrentFilterType
                  );
                }}
              >
                <ReloadIcon />
              </IconButton>
              <FormControl>
                <Select
                  value={this.state.ticketRequestsFilter}
                  onChange={this.handleChangeTicketRequests}
                >
                  <MenuItem value="Toutes les demandes">
                    <IntlMessages id="menuItem.status.demands" />
                  </MenuItem>
                  <MenuItem value="Brouillons">
                    <IntlMessages id="menuItem.status.drafts" />
                  </MenuItem>
                  <MenuItem value="En validations">
                    <IntlMessages id="menuItem.status.validating" />
                  </MenuItem>
                  <MenuItem value="Acceptées">
                    <IntlMessages id="menuItem.status.accepted" />
                  </MenuItem>
                  <MenuItem value="Refusées">
                    <IntlMessages id="menuItem.status.refused" />
                  </MenuItem>
                </Select>
                <FormHelperText>
                  <IntlMessages id="form.status.helper.text" />
                </FormHelperText>
              </FormControl>
              <FormControl style={{ marginLeft: '10px' }}>
                <Select
                  value={this.state.ticketRequestsCurrentFilterType}
                  onChange={this.handleChangeTicketRequestsType}
                >
                  <MenuItem value="all">
                    <IntlMessages id="menuItem.type.all" />
                  </MenuItem>
                  <MenuItem value="accredited">
                    <IntlMessages id="menuItem.type.accredited" />
                  </MenuItem>
                  <MenuItem value="artist">
                    <IntlMessages id="menuItem.type.artists" />
                  </MenuItem>
                  <MenuItem value="guest">
                    <IntlMessages id="menuItem.type.guests" />
                  </MenuItem>
                  <MenuItem value="press">
                    <IntlMessages id="menuItem.type.press" />
                  </MenuItem>
                  <MenuItem value="supplier">
                    <IntlMessages id="menuItem.type.supplier" />
                  </MenuItem>
                  <MenuItem value="sponsor">
                    <IntlMessages id="menuItem.type.sponsor" />
                  </MenuItem>
                  <MenuItem value="vip">
                    <IntlMessages id="menuItem.type.vip" />
                  </MenuItem>
                </Select>
                <FormHelperText>
                  <IntlMessages id="form.type.helper.text" />
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-4 col-xl-3 col-xxl-1">
            <NavLink to="/app/member/accreditation/creation">
              <Button
                type="button"
                style={{ width: '100%' }}
                className="btn-creation"
                variant="outlined"
                color="secondary"
              >
                <IntlMessages id="button.add.accreditation" />
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3 col-lg-3 col-xl-3">
            <IconWithTextCard
              icon={<i className="zmdi zmdi-edit zmdi-hc-2x" />}
              subTitle={<IntlMessages id="menuItem.status.drafts" />}
              title={this.statsTicketRequest.draft}
              cardColor="warning"
            />
          </div>
          <div className="col-12 col-md-3 col-lg-3 col-xl-3">
            <IconWithTextCard
              icon={<i className="zmdi zmdi-hourglass-alt zmdi-hc-2x" />}
              subTitle={<IntlMessages id="menuItem.status.validating" />}
              title={this.statsTicketRequest.review}
              cardColor="info"
            />
          </div>
          <div className="col-12 col-md-3 col-lg-3 col-xl-3">
            <IconWithTextCard
              icon={<i className="zmdi zmdi-check zmdi-hc-2x" />}
              subTitle={<IntlMessages id="menuItem.status.accepted" />}
              title={this.statsTicketRequest.accepted}
              cardColor="primary"
            />
          </div>
          <div className="col-12 col-md-3 col-lg-3 col-xl-3">
            <IconWithTextCard
              icon={<i className="zmdi zmdi-close zmdi-hc-2x" />}
              subTitle={<IntlMessages id="menuItem.status.refused" />}
              title={this.statsTicketRequest.rejected}
              cardColor="secondary"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="jr-card" style={{ padding: '20px' }}>
              <Grid rows={rows} columns={columns}>
                <RowDetailState
                  defaultExpandedRowIds={[]}
                  onExpandedRowIdsChange={onExpandedRowIdsChange}
                />
                <SortingState
                  defaultSorting={[{ columnName: 'city', direction: 'asc' }]}
                />
                <SearchState defaultValue="" />
                <Table
                  columnExtensions={tableColumnExtensions}
                  cellComponent={(props) => (
                    <CellGuest
                      {...props}
                      openModal={this.handleOpenModalView}
                    />
                  )}
                />
                <IntegratedFiltering />
                <IntegratedSorting />
                {/* <VirtualTable
                  estimatedRowHieght={20}
                  cellComponent={CellGuest}
                /> */}
                <TableRowDetail contentComponent={RowDetail} />
                <TableColumnVisibility
                  defaultHiddenColumnNames={['guestEmail']}
                />
                <Toolbar />
                <SearchPanel />
                <ColumnChooser
                /* }toggleButtonComponent={this.toggleIconChooseColumn} */
                />
                <TableHeaderRow showSortingControls />
              </Grid>
            </div>
          </div>
          {isListLoading && <Loading />}
        </div>
        <ModalViewGuest
          open={this.state.openModalViewGuest}
          ticketRequestsDetails={this.props.ticketRequests.details}
          itemsTicketRequests={this.props.ticketRequests.currentItems}
          user={
            this.props.ticketRequests.details
              ? this.props.ticketRequests.details.userProfile
              : ''
          }
          guest={this.state.currentTicketRequest}
          changeStatusOpenModal={(open) =>
            this.setState({ openModalViewGuest: open })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.sdk.auth,
  ticketRequestsList: state.sdk.ticketRequests.ticketRequestsList,
  idProject: state.sdk.user.currentProject.idCurrentProject,
  ticketRequestAccepted: state.sdk.ticketRequests.ticketRequestAccepted,
  ticketRequestRefused: state.sdk.ticketRequests.ticketRequestRefused,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
  ticketRequests: state.sdk.ticketRequests,
});

export default connect(mapStateToProps, {
  getTicketRequestsByProjectRequest,
  getItemsTicketRequestRequest,
  getDetailsTicketRequestRequest,
  getAddressCompanyRequest,
  getUserProjectRequest,
})(GuestList);
