import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Alert } from 'reactstrap';
import {
  getAllAccessZonesRequest,
  getTicketRequest,
  getTicketsByProjectRequest,
  putTicketRequestRequest,
  putItemsTicketRequestRequest,
  getItemsTicketRequestRequest,
} from '../../../../../../config/actions.config';
import AssignmentTickets from '../../../../../Stepper/StepperFormAddGuest/assignmentTickets';
import TicketsRequestHistory from '../../../../TicketsRequestHistory';
import IntlMessages from '../../../../../../util/IntlMessages';

class GuestDetailsTabsContainerActivity extends Component {
  constructor() {
    super();
    this.state = {
      ticket: 'Votre choix',
      nbTicketsAssign: 0,
      showDetailsTicket: false,
      isActionActivate: '',
      showData: this.displayData,
      counter: 0,
      ticketRequestsLoad: false,
      disable: false,
      noItems: false,
    };
    this.items = [];
    this.disable = false;
    this.params = { object_type: 'ticketing', "priceWithVat|eq": 0 };
  }

  componentDidMount() {
    this.props.getTicketsByProjectRequest(this.props.idProject, this.params);
    if (this.props.guest) {
      if (
        this.props.guest.assignmentStatus.props.id ===
          'table.column.status.accepted' ||
        this.props.guest.assignmentStatus.props.id ===
          'table.column.status.refused'
      ) {
        this.setState({ disable: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updatedItems !== this.props.updatedItems) {
      this.setState({
        ticketRequestsLoad: this.props.ticketRequestUpdate.loading,
      });
      if (!this.props.updatedItems.loading) {
        if (
          this.props.updatedItems.items !== undefined &&
          this.props.updatedItems.items !== ''
        ) {
          this.props.updatedItems.items.forEach((data, i) => {
            this.items[i] = {
              product: data.formule.id,
              quantity: data.quantity,
              productLabel: data.formule.name,
              id: data.id,
            };
          });
          this.props.getItemsTicketRequestRequest(
            this.props.ticketRequestDetails.id
          );
        }
        this.setState({});
      }
    }
    if (
      prevProps.ticketRequests !== this.props.ticketRequests ||
      this.items.length === 0
    ) {
      if (
        this.props.itemsTicketRequestss !== undefined &&
        this.props.itemsTicketRequestss !== '' &&
        prevProps.updatedItems === this.props.updatedItems
      ) {
        this.props.itemsTicketRequestss.forEach((data, i) => {
          this.items[i] = {
            product: data.formule.id,
            quantity: data.quantity,
            productLabel: data.formule.name,
            id: data.id,
          };
        });
        this.setState({});
      }
    }
    if (prevProps.guest !== this.props.guest) {
      if (this.props.guest) {
        if (
          this.props.guest.assignmentStatus.props.id ===
            'table.column.status.accepted' ||
          this.props.guest.assignmentStatus.props.id ===
            'table.column.status.refused'
        ) {
          this.disable = true;
          this.setState({ disable: this.disable });
        }
      }
    }
  }

  getDetailTicket = (id) => {
    this.props.getTicketRequest(id);
  };

  setItems = (items) => {
    this.items = items;
    this.setState({ noItems: false });
  };

  putTicketRequest = () => {
    if (this.items.length === 0) {
      this.setState({ noItems: true });
    } else if (this.items.length > 0) {
      this.props.putItemsTicketRequestRequest(
        this.props.ticketRequestsDetails.id,
        { items: this.items }
      );
    }
  };

  render() {
    const detailsTicket = [];
    const tickets = [];

    const { basketNotes } = this.props;

    if (
      this.props.ticketsList.tickets !== undefined &&
      this.props.ticketsList.tickets !== ''
    ) {
      this.props.ticketsList.tickets.forEach((data, i) => {
        tickets[i] = { value: data.id, label: data.name };
      });
    }

    if (
      this.props.ticketRequests &&
      this.props.ticketRequests.details !== undefined &&
      this.props.ticketRequests.details !== ''
    ) {
      if (
        this.props.ticketRequests.details[this.state.ticketRequests] !==
        undefined
      ) {
        detailsTicket[0] = {
          value: this.props.ticketRequests.details[this.state.ticket].article,
          label: this.props.ticketRequests.details[this.state.ticket].article,
        };
      }
    }

    return (
      <div className="row" style={{ paddingLeft: '15px', marginRight: '15px' }}>
        <div className="col-12" style={{ marginTop: '30px' }}>
          <h3 style={{ color: 'black' }}>
            <IntlMessages id="modal.activity.tab.title.tickets" />
          </h3>
          <hr style={{ marginBottom: 20 + 'px' }} />
        </div>
        <div className="col-12 mb-4">
          <AssignmentTickets
            disable={this.state.disable}
            ticketsList={tickets}
            getDetailTicket={this.getDetailTicket}
            ticket={
              this.props.detailsTicketRequest
                ? this.props.detailsTicketRequest.details
                : ''
            }
            items={this.items}
            setItems={this.setItems}
            itemsModalGuest
          />
          {this.state.noItems ? (
            <Alert
              className="shadow-lg"
              color="danger"
              style={{ textAlign: 'center', marginBottom: '15px' }}
            >
              <IntlMessages id="modal.activity.tab.alert" />
            </Alert>
          ) : (
            ''
          )}
          {!this.state.disable ? (
            <div className="col-12" style={{ marginBottom: '15px' }}>
              <Button
                variant="outlined"
                disabled={this.state.disable}
                color="primary"
                onClick={this.putTicketRequest}
                style={{
                  color: '#0077ff',
                  borderColor: '#0077ff',
                  fontSize: '12px',
                  float: 'right',
                }}
              >
                {this.state.ticketRequestsLoad ? (
                  <CircularProgress style={{ width: '80%', height: 'auto' }} />
                ) : (
                  <IntlMessages id="modal.activity.tab.button.save" />
                )}
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="col-12" style={{ marginTop: '30px' }}>
          <h3 style={{ color: 'black' }}>
            <IntlMessages id="modal.activity.tab.title.history" />
          </h3>
          <hr style={{ marginBottom: 20 + 'px' }} />
        </div>
        <TicketsRequestHistory basketNotes={basketNotes} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketsList: state.sdk.tickets.ticketsList,
  accessZones: state.sdk.accessZones,
  idProject: state.sdk.user.currentProject.idCurrentProject,
  ticketRequests: state.sdk.ticketRequests,
  ticketRequestUpdate: state.sdk.ticketRequests.ticketRequestUpdate,
  itemsTicketRequestss: state.sdk.ticketRequests.currentItems,
  updatedItems: state.sdk.ticketRequests.updatedItems,
  basketNotes: state.sdk.baskets.basketNotes,
  ticketRequestDetails: state.sdk.ticketRequests.details,
});

export default connect(mapStateToProps, {
  getAllAccessZonesRequest,
  getTicketsByProjectRequest,
  getTicketRequest,
  getItemsTicketRequestRequest,
  putTicketRequestRequest,
  putItemsTicketRequestRequest,
})(GuestDetailsTabsContainerActivity);
