import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { getBasketNotesRequest } from '../../../config/actions.config';
import GuestDetailsSide from '../GuestDetails/GuestDetailsSide';
import GuestDetailsTabs from '../GuestDetails/GuestDetailsTabs';
import LoaderModal from '../../Loader/LoaderModal';

class ModalViewGuest extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  handleRequestClose = () => {
    this.props.changeStatusOpenModal(false);
  };

  handleChange = (name) => (value) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    this.handleRequestClose();
    event.preventDefault();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ticketRequestsDetails !== this.props.ticketRequestsDetails) {
      if (this.props.ticketRequestsDetails) {
        if (this.props.ticketRequestsDetails.basket) {
          this.props.getBasketNotesRequest(
            this.props.ticketRequestsDetails.basket.id
          );
        }
      }
    }
  }

  render() {
    const { loadingUser } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleRequestClose}
          maxWidth="md"
          fullWidth
        >
          <div className="row widthModalGuest" style={{ minHeight: '48rem' }}>
            <GuestDetailsSide user={this.props.user} guest={this.props.guest} />
            {!loadingUser ? (
              <GuestDetailsTabs
                user={this.props.user}
                guest={this.props.guest}
                ticketRequestsDetails={this.props.ticketRequestsDetails}
                itemsTicketRequests={this.props.itemsTicketRequests}
              />
            ) : (
              ''
            )}
            <LoaderModal loading={loadingUser} />
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, { getBasketNotesRequest })(
  ModalViewGuest
);
