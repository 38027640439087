import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IntlMessages from '../../util/IntlMessages';
import CustomScrollbars from '../../util/CustomScrollbars';

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      <ul className="nav-menu">{children}</ul>
    </div>
  );
}

TabContainer.propTypes = {
  dir: PropTypes.string.isRequired,
};

class SidenavContent extends Component {
  constructor() {
    super();
    this.state = {
      brandingName: '',
      value: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      if (this.props.project.brandingName) {
        this.setState({ brandingName: this.props.project.brandingName });
      }
    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ].some((fn) => {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  render() {
    const { brandingName } = this.state;
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function () {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains('open'))
          ) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav) {
        if (activeNav.classList.contains('sub-menu')) {
          this.closest(activeNav, 'li').classList.add('open');
        } else {
          this.closest(activeLi, 'li').classList.add('open');
        }
      }
    } catch (error) {
      console.error(error);
    }

    return (
      <CustomScrollbars className="scrollbar">
        <TabContainer dir="menu">
          <li className="ui_tooltip menu">
            <Button className="void title-sidebar">
              <i className="zmdi zmdi-accounts-add zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidenav.link.title" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/index">
                  <span className="nav-text">
                    <IntlMessages id="sidenav.link.list.acred" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/member/accreditation/creation"
                >
                  <span className="nav-text">
                    <IntlMessages id="sidenav.link.add.acred" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="ui_tooltip menu">
            <Button className="void title-sidebar">
              <i className="zmdi zmdi-link" />
              <span className="nav-text">
                <IntlMessages id="sidenav.link.favorite" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    'https://frontoffice.byemisys.com/account/' +
                    brandingName +
                    '/fr/request/new/accredited'
                  }
                >
                  <span className="nav-text">
                    <IntlMessages id="sidenav.link.favorite.accredited" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    'https://frontoffice.byemisys.com/account/' +
                    brandingName +
                    '/fr/request/new/artist'
                  }
                >
                  <span className="nav-text">
                    <IntlMessages id="sidenav.link.favorite.artists" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    'https://frontoffice.byemisys.com/account/' +
                    brandingName +
                    '/fr/request/new/press'
                  }
                >
                  <span className="nav-text">
                    <IntlMessages id="sidenav.link.favorite.press" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    'https://frontoffice.byemisys.com/account/' +
                    brandingName +
                    '/fr/request/new/supplier'
                  }
                >
                  <span className="nav-text">
                    <IntlMessages id="sidenav.link.favorite.suppliers" />
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </TabContainer>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.sdk.project.projectDetails,
});

export default withRouter(connect(mapStateToProps)(SidenavContent));
