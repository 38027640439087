import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

class ModalRejectTicketRequest extends Component {
  constructor() {
    super();
    this.motif = {
      denyReasonPublic: '',
      denyReasonPrivate: '',
    };
  }

  handleChange = (name) => (event) => {
    this.motif[name] = event.target.value;
    this.setState({});
  };

  handleRequestClose = () => {
    this.props.onClose();
  };

  submitRejectWithReason = (e) => {
    e.preventDefault();
    this.props.rejectTicketRequest(this.motif);
    this.props.onClose();
  };

  render() {
    const { open } = this.props;

    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleRequestClose}
          onSubmit={this.submitRejectWithReason}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Refuser la demande</DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-12">
                  <TextField
                    id="motif"
                    label="Motif de refus envoyé au demandeur..."
                    style={{ width: `${100}%` }}
                    margin="normal"
                    onChange={this.handleChange('denyReasonPublic')}
                    value={this.motif.denyReasonPublic}
                    type="text"
                    helperText="Le motif du refus sera envoyé à la personne ayant fait la demande"
                    fullWidth
                    multiline
                  />
                </div>
                <div className="col-12 col-md-12">
                  <TextField
                    id="motif"
                    label="Complément d'information concernant le refus..."
                    style={{ width: `${100}%` }}
                    margin="normal"
                    onChange={this.handleChange('denyReasonPrivate')}
                    value={this.motif.denyReasonPrivate}
                    type="text"
                    helperText="Ce commentaire sera visible uniquement par les administrateurs"
                    fullWidth
                    multiline
                  />
                </div>
              </div>
              <DialogActions>
                <Button onClick={() => this.props.onClose()} color="primary">
                  Annuler
                </Button>
                <Button type="submit" color="primary">
                  Envoyer
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ModalRejectTicketRequest;
