import React, { Component } from 'react';
import AssignTicketsItem from './assignTicketsItem';
import AssignTicketsItemAdd from './assignTicketsItemAdd';

class AssignmentTickets extends Component {
  constructor() {
    super();
    this.state = {
      firstLoad: true,
    };
    this.items = [];
    this.disable = false;
  }

  componentDidMount() {
    if (this.props.items !== undefined) {
      if (
        this.props.items !== '' &&
        this.props.items !== null &&
        this.props.items !== undefined
      ) {
        this.items = this.props.items;
      }
    }
    this.setState({});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.items = this.props.items;
      this.setState({});
    }
    if (prevProps.disable !== this.props.disable) {
      this.disable = this.props.disable;
    }
  }

  addChangeTicket = (item) => {
    this.items.push(item);
    this.props.setItems(this.items);
    this.setState({});
  };

  onAddItem = (ticket) => {
    // TODO: Add item
    console.log('-> ticket', ticket);
  };

  onDeleteItem = (index) => {
    this.items[index] = undefined;
    const newListItems = [];
    this.items.forEach((data) => {
      if (data !== undefined) {
        newListItems.push(data);
      }
    });
    this.props.setItems(newListItems);
    this.setState({});
  };

  handleChangeTicket = (quantity, product, label, index) => {
    if (this.items[index] !== null) {
      this.items[index].product = product;
      this.items[index].quantity = quantity;
      this.items[index].productLabel = label;
      this.props.setItems(this.items);
    }
    this.setState({});
  };

  render() {
    if (this.items.length > 0 && this.state.firstLoad) {
      const newList = {};
      this.props.items.forEach((data) => {
        if (!Object.prototype.hasOwnProperty.call(newList, data.product)) {
          newList[data.product] = {
            product: null,
            quantity: null,
            productLabel: null,
            id: null,
          };
          newList[data.product].quantity = data.quantity - 1;
          newList[data.product].product = data.product;
          newList[data.product].productLabel = data.productLabel;
          newList[data.product].id = data.id;
        }
        newList[data.product].quantity++;
      });
      this.items = [];
      this.props.items.forEach((data, i) => {
        if (Object.keys(newList).length > i) {
          this.items[i] = newList[data.product];
        }
      });
      this.setState({ firstLoad: false });
    }

    return (
      <div>
        {this.items.map((data, i) =>
          data !== undefined ? (
            <AssignTicketsItem
              disable={this.disable}
              ticketItem={data}
              key={i}
              keyItem={i}
              deleteItem={this.onDeleteItem}
              changeItem={this.handleChangeTicket}
            />
          ) : (
            ''
          )
        )}
        <AssignTicketsItemAdd
          disable={this.disable}
          addTicket={this.onAddItem}
          keyItems={this.items.length}
          addChangeTicket={this.addChangeTicket}
        />
      </div>
    );
  }
}

export default AssignmentTickets;
