import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { DeleteOutlineOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getTicketRequest } from '../../../config/actions.config';

class AssignTicketsItem extends Component {
  constructor() {
    super();
    this.state = {
      showDetailsTicket: false,
      isActionActivate: '',
      showData: this.displayData,
      counter: 0,
    };
    this.displayData = [];
    this.productLabel = '';
    this.product = null;
    this.quantity = null;
    this.index = '';
  }

  handleChange = (event) => {
    this.quantity = parseInt(event.target.value);
    this.props.changeItem(
      this.quantity,
      this.product,
      this.productLabel,
      this.index
    );
    this.setState({});
  };

  getOption = (option) => {
    if (this.product) {
      if (option.value === this.product) {
        this.productLabel = option.label;
        this.props.changeItem(
          this.quantity,
          this.product,
          option.label,
          this.index
        );
      }
    } else {
      if (option.value === this.props.ticketItem.quantity) {
        this.productLabel = option.label;
        this.props.changeItem(
          this.props.ticketItem.quantity,
          this.props.ticketItem.product,
          option.label,
          this.index
        );
      }
    }

    if (option.value === this.product) {
      this.productLabel = option.label;
      this.props.changeItem(
        this.quantity,
        this.product,
        this.productLabel,
        this.index
      );
    }
  };

  handleChangeTickets = (event) => {
    this.props.getTicketRequest(event.target.value);
    this.product = event.target.value;
    this.props.changeItem(
      this.quantity,
      event.target.value,
      this.productLabel,
      this.index
    );
    this.setState({ showDetailsTicket: true });
  };

  componentDidMount() {
    this.props.getTicketRequest(this.props.ticketItem.product);
    this.quantity = this.props.ticketItem.quantity;
    this.product = this.props.ticketItem.product;
    this.index = this.props.keyItem;
  }

  deleteItems = () => {
    this.props.deleteItem(this.props.keyItem);
    this.setState({});
  };

  render() {
    const detailsTicket = [];
    const products = [];

    if (
      this.props.ticketsList.tickets !== undefined &&
      this.props.ticketsList.tickets !== ''
    ) {
      this.props.ticketsList.tickets.forEach((data, i) => {
        if (data.isPrintAtHome) {
          products[i] = { value: data.id, label: data.name };
        }
      });
    }
    if (
      this.props.ticketRequests.details !== undefined &&
      this.props.ticketRequests.details !== ''
    ) {
      if (this.props.ticketRequests.details[this.product] !== undefined) {
        if (this.props.ticketRequests.details[this.product].length > 1) {
          this.props.ticketRequests.details[this.product].forEach((data, i) => {
            detailsTicket[i] = {
              value: data.article,
              label: data.article,
            };
          });
        } else {
          detailsTicket[0] = {
            value: this.props.ticketRequests.details[this.product].article,
            label: this.props.ticketRequests.details[this.product].article,
          };
        }
      }
    }
    return (
      <div className="col-12">
        <div className="row">
          <div className="col-2">
            <div className="form-group">
              <TextField
                required
                disabled={this.props.disable}
                id="quantity"
                label="Nb"
                margin="normal"
                type="number"
                inputProps={{ min: '0' }}
                value={
                  this.quantity ? this.quantity : this.props.ticketItem.quantity
                }
                onChange={this.handleChange}
                fullWidth
              />
            </div>
          </div>
          <div className="col-5">
            <TextField
              id="standard-select-currency"
              disabled={this.props.disable}
              select
              value={
                this.product ? this.product : this.props.ticketItem.product
              }
              style={{ width: '100%', marginTop: 32 + 'px' }}
              margin="normal"
              onChange={() => this.handleChangeTickets('product')}
            >
              {products.map((option, i) => (
                <MenuItem
                  value={option.value}
                  onClick={this.getOption(option)}
                  key={i}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-5">
            <div className="row">
              {detailsTicket.map((option, i) =>
                option.label !== null &&
                option.label !== undefined &&
                option.label !== '' ? (
                  <div key={i} style={{ marginTop: 35 + 'px' }}>
                    <Chip
                      style={{
                        margin: '5px',
                        color: '#0077ff',
                        borderColor: '#0077ff',
                      }}
                      label={option.label}
                      variant="outlined"
                    />
                  </div>
                ) : (
                  ''
                )
              )}
              {this.props.disable ? (
                ''
              ) : (
                <div className="col-2" style={{ marginTop: 35 + 'px' }}>
                  <Button
                    disabled={this.props.disable}
                    variant="outlined"
                    color="secondary"
                    style={{ minWidth: 0, padding: '6.5px' }}
                    onClick={this.deleteItems}
                  >
                    <DeleteOutlineOutlined />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketsList: state.sdk.tickets.ticketsList,
  accessZones: state.sdk.accessZones,
  idProject: state.sdk.user.currentProject.idCurrentProject,
  ticketRequests: state.sdk.ticketRequests,
});

export default connect(mapStateToProps, { getTicketRequest })(
  AssignTicketsItem
);
