import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import SvgIcon from '@material-ui/core/SvgIcon';

const getDisplayString = (sub) => {
  const arr = sub.split('-');
  if (arr.length > 1) {
    return (
      arr[0].charAt(0).toUpperCase() +
      arr[0].slice(1) +
      ' ' +
      arr[1].charAt(0).toUpperCase() +
      arr[1].slice(1)
    );
  }
  return sub.charAt(0).toUpperCase() + sub.slice(1);
};

const ContainerHeader = ({ title, match }) => {
  const path = match.path.substr(1);
  const subPath = path.split('/');
  return (
    <div>
      <div className='page-heading no-card title-page-heading d-sm-flex justify-content-sm-between align-items-sm-center'>
        <h2 className='title mb-3 mb-sm-0'>
          <i className='zmdi zmdi-star-outline mr-2' />
          {title}
        </h2>
      </div>
      <div className='page-heading no-card sub-title-page-heading d-sm-flex justify-content-sm-between align-items-sm-center'>
        <Breadcrumb className='mb-0' tag='nav'>
          {subPath.map((sub, index) => (
            <BreadcrumbItem
              active={subPath.length === index + 1}
              tag={subPath.length === index + 1 ? 'span' : 'a'}
              key={index}
              href='/#/'
            >
              {index !== 0 ? (
                getDisplayString(sub)
              ) : (
                <SvgIcon style={{ fontSize: 19 + 'px' }}>
                  <path d='M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z' />
                </SvgIcon>
              )}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </div>
    </div>
  );
};

export default ContainerHeader;
