import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import {
  getAllAccessZonesRequest,
  getTicketRequest,
  getTicketsByProjectRequest,
} from '../../../../../../config/actions.config';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Icon from '@material-ui/core/Icon';
import IconAddCircle from '@material-ui/icons/AddCircleOutline';
import OrderTable from './GuestDetailsTabsContainerAssignmentTable';

class GuestDetailsTabsContainerAssignment extends Component {
  constructor() {
    super();
    this.state = {
      emailGuest: '',
      tickets: 'Votre choix',
      nbTicketsAssign: '',
      showAccessZones: false,
      isActionActivate: '',
      showData: this.displayData,
      counter: 0,
    };
    this.displayData = [];
    this.appendData = this.appendData.bind(this);
    this.params = { object_type: 'ticketing', "priceWithVat|eq": 0 };
  }

  componentDidMount() {
    this.props.getTicketsByProjectRequest(this.props.idProject, this.params);
    this.props.getAllAccessZonesRequest(this.props.idProject);
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeTickets = (name) => (event) => {
    this.props.getTicketRequest(event.target.value);
    this.setState({ showAccessZones: true, [name]: event.target.value });
  };

  appendData = (tickets, accessZones) => {
    this.displayData[this.state.counter] = (
      <div className="col-12">
        <div className="row">
          <div className="col-1">
            <div className="form-group">
              <TextField
                key={this.state.counter}
                required
                id="nbTicketsAssign"
                label="Nb"
                margin="normal"
                type="number"
                value={this.state.nbTicketsAssign}
                onChange={this.handleChange('nbTicketsAssign')}
                fullWidth
              />
            </div>
          </div>
          <div className="col-4">
            <TextField
              id="standard-select-currency"
              select
              key={this.state.counter}
              onChange={this.handleChangeTickets('tickets')}
              value={this.state.tickets}
              style={{ width: '100%', marginTop: 32 + 'px' }}
              helperText="Merci de choisir un ticket"
              margin="normal"
            >
              {tickets.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          {this.state.showAccessZones ? (
            <div className="col-7">
              <div className="row">
                <div className="col-10" style={{ marginTop: 35 + 'px' }}>
                  {accessZones.map((option, i) => (
                    <Chip
                      label={option.label}
                      key={i}
                      component="a"
                      style={{ margin: 5 + 'px' }}
                      clickable
                      variant="outlined"
                    />
                  ))}
                </div>
                <div className="col-2" style={{ marginTop: 35 + 'px' }}>
                  <Button variant="outlined" color="secondary">
                    <DeleteOutlinedIcon onClick={this.deleteTicketLine} />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-1" style={{ marginTop: 35 + 'px' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.appendData(tickets)}
              >
                <Icon color="primary">add_circle_outline</Icon>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
    this.setState({
      showdata: this.displayData,
      counter: this.state.counter + 1,
    });
  };

  render() {
    const accessZones = [];

    const tickets = [];
    if (
      this.props.accessZones.accessZonesList !== undefined &&
      this.props.accessZones.accessZonesList !== ''
    ) {
      this.props.accessZones.accessZonesList.forEach((data, i) => {
        accessZones[i] = { value: data.id, label: data.name };
      });
    }
    if (
      this.props.ticketsList.tickets !== undefined &&
      this.props.ticketsList.tickets !== ''
    ) {
      this.props.ticketsList.tickets.forEach((data, i) => {
        tickets[i] = { value: data.id, label: data.name };
      });
    }

    return (
      <div className="row" style={{ paddingLeft: '15px', marginRight: '15px' }}>
        <div className="col-12" style={{ marginTop: 30 + 'px' }}>
          <h3 style={{ color: 'black' }}>Liste des attributions</h3>
          <hr style={{ marginBottom: 10 + 'px' }} />
        </div>
        <OrderTable />
        <div className="col-12" style={{ marginTop: 30 + 'px' }}>
          <h3 style={{ color: 'black' }}>Ajout d`&apos;`un bénéficiaire</h3>
          <hr style={{ marginBottom: 10 + 'px' }} />
        </div>
        <div className="col-4 textFieldLabel">
          <h4>Email *</h4>
        </div>
        <div className="col-5">
          <div className="form-group">
            <TextField
              style={{ marginTop: '2px' }}
              required
              id="emailGuest"
              margin="normal"
              fullWidth
              value={this.state.emailGuest}
              onChange={this.handleChange('emailGuest')}
            />
          </div>
        </div>
        <div className="col-12" style={{ marginTop: 30 + 'px' }}>
          <h3 style={{ color: 'black' }}>Attribuer des tickets</h3>
          <hr style={{ marginBottom: 10 + 'px' }} />
        </div>
        <div className="col-1">
          <div className="form-group">
            <TextField
              required
              id="nbTicketsAssign"
              label="Nb"
              margin="normal"
              type="number"
              value={this.state.nbTicketsAssign}
              onChange={this.handleChange('nbTicketsAssign')}
              fullWidth
            />
          </div>
        </div>
        <div className="col-4">
          <TextField
            id="standard-select-currency"
            select
            onChange={this.handleChangeTickets('tickets')}
            value={this.state.tickets}
            style={{ width: '100%', marginTop: 32 + 'px' }}
            helperText="Merci de choisir un ticket"
            margin="normal"
          >
            {tickets.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {this.state.showAccessZones ? (
          <div className="col-7">
            <div className="row">
              <div className="col-10" style={{ marginTop: 35 + 'px' }}>
                {accessZones.map((option, i) => (
                  <Chip
                    style={{
                      margin: '5px',
                      color: '#0077ff',
                      borderColor: '#0077ff',
                    }}
                    label={option.label}
                    key={i}
                    variant="outlined"
                  />
                ))}
              </div>
              <div className="col-2" style={{ marginTop: 35 + 'px' }}>
                <Button variant="outlined" color="secondary">
                  <DeleteOutlinedIcon onClick={this.deleteTicketLine} />
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-1" style={{ marginTop: 35 + 'px' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ minWidth: 0, padding: '6.5px' }}
              onClick={() => this.onClickAdd()}
            >
              <IconAddCircle color="primary" />
            </Button>
          </div>
        )}
        {this.displayData.map((data) => data)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketsList: state.sdk.formules.ticketsList,
  accessZones: state.sdk.accessZones,
  idProject: state.sdk.user.currentProject.idCurrentProject,
});

export default connect(mapStateToProps, {
  getAllAccessZonesRequest,
  getTicketsByProjectRequest,
  getTicketRequest,
})(GuestDetailsTabsContainerAssignment);
