import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import {
  getDetailsCompanyRequest,
  searchCompanyByNameRequest,
  getAddressCompanyRequest,
  putAddressRequest,
  putCompanyRequest,
  putTicketRequestRequest,
  postCompanyRequest,
  putCompanyCollaboratorRequest,
  postAddressCompanyRequest,
  postCompanyCollaboratorRequest,
} from '../../../../../../config/actions.config';
// import DropdownResearchCompany from '../../../../../DropdownResearch/DropdownResearchCompany';
import IntlMessages from '../../../../../../util/IntlMessages';

class GuestDetailsTabsContainerInfosCompany extends Component {
  constructor() {
    super();

    this.state = {
      companyLoad: false,
      isNewCompany: false,
      showForm: false,
    };

    this.company = {
      id: null,
      name: '',
      email: '',
      activityArea: '',
    };

    this.job = '';

    this.addressCompany = {
      id: null,
      street: '',
      zipCode: '',
      city: '',
      country: '',
    };

    this.guest = '';

    this.userCompany = '';
    this.timeout = '';
    this.params = { limit: 4 };
  }

  componentDidMount() {
    if (this.props.guestCompany !== undefined) {
      if (
        this.props.guestCompany !== '' &&
        this.props.guestCompany !== null &&
        this.props.guestCompany !== undefined
      ) {
        this.company.name = this.props.guestCompany.name;
        this.company.id = this.props.guestCompany.id;
        this.company.email = this.props.guestCompany.email;
        this.company.activityArea = this.props.guestCompany.activityArea;
        this.job = this.props.post;
        this.guest = this.props.guest;
        this.userCompany = this.props.userCompany;
        this.setState({ showForm: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.companies.addressCompany.address !==
      this.props.companies.addressCompany.address
    ) {
      this.setState({});
    }

    if (prevProps.guestCompany !== this.props.guestCompany) {
      this.company.name = this.props.guestCompany.name;
      this.company.id = this.props.guestCompany.id;
      this.company.email = this.props.guestCompany.email;
      this.company.activityArea = this.props.guestCompany.activityArea;
      this.setState({ showForm: true });
      this.setState({});
    }
    if (prevProps.userCompany !== this.props.userCompany) {
      this.userCompany = this.props.userCompany;
      this.setState({});
    }
    if (prevProps.guest !== this.props.guest) {
      this.guest = this.props.guest;
      this.setState({});
    }
    if (
      prevProps.companyCreated !== this.props.companyCreated &&
      !this.addressCompany.id
    ) {
      if (this.props.companyCreated.company.id) {
        this.props.postAddressCompanyRequest(
          this.props.companyCreated.company.id,
          this.addressCompany
        );
        this.props.postCompanyCollaboratorRequest(
          this.props.companyCreated.company.id,
          this.props.userProject.partnerProject.id
        );
      }
    }
    if (prevProps.addressCompany !== this.props.addressCompany) {
      if (!this.props.addressCompany.loading) {
        if (this.props.addressCompany) {
          if (this.props.addressCompany.address.length > 0) {
            this.addressCompany.street =
              this.props.addressCompany.address[
                this.props.addressCompany.address.length - 1
              ].street;
            this.addressCompany.id =
              this.props.addressCompany.address[
                this.props.addressCompany.address.length - 1
              ].id;
            this.addressCompany.zipCode =
              this.props.addressCompany.address[
                this.props.addressCompany.address.length - 1
              ].zipCode;
            this.addressCompany.city =
              this.props.addressCompany.address[
                this.props.addressCompany.address.length - 1
              ].city;
            this.addressCompany.country =
              this.props.addressCompany.address[
                this.props.addressCompany.address.length - 1
              ].country;
            this.setState({});
          }
        }
      }
    }
    if (
      this.props.companyDetails !== undefined &&
      this.props.companyDetails !== ''
    ) {
      if (
        prevProps.companyDetails.details !==
          this.props.companyDetails.details &&
        this.props.companyDetails.details !== ''
      ) {
        this.company = this.props.companyDetails.details;
        this.setState({});
      }
    }
    if (prevProps.post !== this.props.post) {
      this.job = this.props.post;
      this.setState({});
    }

    if (
      prevProps.newCompanyCollaborator !== this.props.newCompanyCollaborator
    ) {
      if (!this.props.newCompanyCollaborator.loading) {
        this.props.putTicketRequestRequest(this.props.ticketRequests.id, {
          usercompany: this.props.newCompanyCollaborator.userCompany.id,
        });
      }
    }
  }

  onHandleChangeCompany = (company) => {
    if (company !== null && company !== '' && company !== undefined) {
      if (company.id !== undefined) {
        this.props.getDetailsCompanyRequest(company.id);
        this.props.getAddressCompanyRequest(company.id);
      }
    }
    this.company = company;
    this.setState({ showForm: true, isNewCompany: false });
  };

  onHandleChangeInput = (companyName) => {
    if (companyName !== '') {
      this.params.name = { operation: 'contain', value: companyName };
    } else {
      delete this.params.name;
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.searchCompanyByNameRequest(
        this.props.organization.id,
        this.params
      );
    }, 200);
  };

  clearForm = () => {
    this.company = {
      name: '',
      email: '',
      activityArea: '',
    };

    this.addressCompany = {
      street: '',
      zipCode: '',
      city: '',
      country: '',
    };
  };

  newCompanyForm = () => {
    this.clearForm();
    this.setState({ isNewCompany: true, showForm: true });
  };

  saveCompany = () => {
    if (!this.state.isNewCompany && this.company.id && this.userCompany) {
      this.props.putCompanyCollaboratorRequest(this.userCompany.id, {
        post: this.job,
        attendee: this.userCompany.attendee.id,
        company: this.company.id,
      });
      this.props.putCompanyRequest(this.company.id, this.company);
      if (this.addressCompany.id !== null) {
        this.props.putAddressRequest(
          this.addressCompany.id,
          this.addressCompany
        );
      } else {
        this.props.postAddressCompanyRequest(
          this.company.id,
          this.addressCompany
        );
      }
    } else if (this.state.isNewCompany && this.company.id === undefined) {
      this.props.postCompanyRequest(this.company, this.props.organization.id);
    } else if (this.company.id) {
      this.props.putCompanyRequest(this.company.id, this.company);
      this.props.postCompanyCollaboratorRequest(
        this.company.id,
        this.props.userProject.partnerProject.id
      );
    }
  };

  handleChange = (name, address) => (event) => {
    this.setState({ [name]: event.target.value });
    if (name === 'job') {
      this.job = event.target.value;
    }
    if (name === 'addressCompany') {
      if (address === 'zipCode') {
        this.addressCompany[address] = parseInt(event.target.value);
      } else {
        this.addressCompany[address] = event.target.value;
      }
    }
    if (name !== 'addressCompany' && name !== 'job') {
      this.company[name] = event.target.value;
    }
  };

  render() {
    /*
    let companySuggestion = [];
    let suggestionIsLoading = true;
    if (
      this.props.search.companyByName !== '' &&
      this.props.search.companyByName !== undefined
    ) {
      suggestionIsLoading = this.props.search.companyByName.loading;
      if (
        this.props.search.companyByName.companies !== '' &&
        this.props.search.companyByName.companies !== undefined
      ) {
        companySuggestion = this.props.search.companyByName.companies.data;
      }
    }
    */

    const country = [
      {
        value: 'FR',
        label: 'France',
      },
      {
        value: 'BE',
        label: 'Belgique',
      },
      {
        value: 'DE',
        label: 'Allemagne',
      },
      {
        value: 'NL',
        label: 'Pays-Bas',
      },
    ];

    return (
      <div className="row" style={{ paddingLeft: '15px', marginRight: '15px' }}>
        <div className="col-12" style={{ marginTop: 30 + 'px' }}>
          <h3 style={{ color: 'black' }}>
            <IntlMessages id="modal.company.tab.title" />
          </h3>
          <hr style={{ marginBottom: 10 + 'px' }} />
        </div>
        {/*
        <div className="col-4 textFieldLabel">
          <h4>
            <IntlMessages id="modal.company.tab.search.title" />
          </h4>
        </div>
        <div className="col-5">
          <div className="row">
            <div className="col-8">
              <DropdownResearchCompany
                onHandleChange={this.onHandleChangeCompany}
                companySuggestions={companySuggestion}
                onHandleChangeInput={this.onHandleChangeInput}
                isLoading={suggestionIsLoading}
              />
            </div>
            <div className="col-3">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.newCompanyForm()}
              >
                <IntlMessages id="modal.company.tab.new.button" />
              </Button>
            </div>
          </div>
        </div>
        */}
        <div className="col-12">
          {this.state.showForm ? (
            <div className="row">
              <div className="col-4 textFieldLabel mt-4">
                <h4>
                  <IntlMessages id="modal.company.tab.company.name" />
                </h4>
              </div>
              <div className="col-5 mt-4">
                <div className="form-group">
                  <TextField
                    style={{ marginTop: '3px' }}
                    id="companyName"
                    disabled
                    margin="normal"
                    fullWidth
                    value={this.company.name ? this.company.name : ''}
                    onChange={this.handleChange('name')}
                  />
                </div>
              </div>
              <div className="col-4 textFieldLabel">
                <h4>
                  <IntlMessages id="modal.company.tab.company.adress" />
                </h4>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <TextField
                    style={{ marginTop: '3px' }}
                    id="street"
                    disabled
                    margin="normal"
                    fullWidth
                    value={
                      this.addressCompany.street
                        ? this.addressCompany.street
                        : ''
                    }
                    onChange={this.handleChange('addressCompany', 'street')}
                  />
                </div>
              </div>
              {/*
            <div className={"col-4 textFieldLabel"}>
                <h4>Adresse (ligne 2) </h4>
            </div>
            <div className={"col-5"}>
                <div className="form-group">
                    <TextField
                        id="userName"
                        label="Adresse (ligne 2)"
                        margin="normal"
                        fullWidth
                    />
                </div>
            </div>
            */}
              <div className="col-4 textFieldLabel">
                <h4>
                  <IntlMessages id="modal.company.tab.company.town" />
                </h4>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <TextField
                    style={{ marginTop: '3px' }}
                    id="zipCode"
                    disabled
                    margin="normal"
                    fullWidth
                    type="number"
                    value={
                      this.addressCompany.zipCode
                        ? this.addressCompany.zipCode
                        : ''
                    }
                    onChange={this.handleChange('addressCompany', 'zipCode')}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <TextField
                    style={{ marginTop: '3px' }}
                    id="city"
                    disabled
                    margin="normal"
                    fullWidth
                    value={
                      this.addressCompany.city ? this.addressCompany.city : ''
                    }
                    onChange={this.handleChange('addressCompany', 'city')}
                  />
                </div>
              </div>
              <div className="col-4 textFieldLabel">
                <h4>
                  <IntlMessages id="modal.company.tab.company.country" />
                </h4>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <TextField
                    style={{ marginTop: '3px' }}
                    id="country"
                    disabled
                    select
                    margin="normal"
                    fullWidth
                    value={
                      this.addressCompany.country
                        ? this.addressCompany.country
                        : ''
                    }
                    onChange={this.handleChange('addressCompany', 'country')}
                  >
                    {country.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <div className="col-4 textFieldLabel">
                <h4>
                  <IntlMessages id="modal.company.tab.company.mail" />
                </h4>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <TextField
                    disabled
                    style={{ marginTop: '3px' }}
                    id="email"
                    margin="normal"
                    fullWidth
                    value={this.company.email ? this.company.email : ''}
                    onChange={this.handleChange('email')}
                  />
                </div>
              </div>
              {/*
              <div className="col-4 textFieldLabel">
                <h4>
                  <IntlMessages id="modal.company.tab.company.sector" />
                </h4>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <TextField
                    style={{ marginTop: '3px' }}
                    id="activityArea"
                    margin="normal"
                    fullWidth
                    value={
                      this.company.activityArea ? this.company.activityArea : ''
                    }
                    onChange={this.handleChange('activityArea')}
                  />
                </div>
              </div>
              <div className="col-4 textFieldLabel">
                <h4>
                  <IntlMessages id="modal.company.tab.company.job" />
                </h4>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <TextField
                    style={{ marginTop: '3px' }}
                    id="jobGuest"
                    margin="normal"
                    fullWidth
                    value={this.job}
                    onChange={this.handleChange('job')}
                  />
                </div>
              </div>
              */}
              <div className="col-12" style={{ marginBottom: '15px' }}>
                <Button
                  disabled
                  variant="outlined"
                  color="primary"
                  onClick={this.saveCompany}
                  style={{ float: 'right' }}
                >
                  {this.state.companyLoad ? (
                    <CircularProgress
                      style={{ width: '80%', height: 'auto' }}
                    />
                  ) : (
                    <IntlMessages id="modal.company.tab.button.save" />
                  )}
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  search: state.sdk.search,
  companyDetails: state.sdk.companies.detailsCompany,
  company: state.sdk.companies.company,
  addressCompany: state.sdk.companies.addressCompany,
  organization: state.sdk.project.projectDetails.organization,
  companyCreated: state.sdk.companies.newCompany,
  companies: state.sdk.companies,
  userProject: state.sdk.user.userProject,
  newCompanyCollaborator: state.sdk.companies.newCompanyCollaborator,
  ticketRequests: state.sdk.ticketRequests.details,
});

export default connect(mapStateToProps, {
  getDetailsCompanyRequest,
  searchCompanyByNameRequest,
  getAddressCompanyRequest,
  putAddressRequest,
  putCompanyRequest,
  putTicketRequestRequest,
  postCompanyRequest,
  putCompanyCollaboratorRequest,
  postAddressCompanyRequest,
  postCompanyCollaboratorRequest,
})(GuestDetailsTabsContainerInfosCompany);
