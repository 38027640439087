import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar/index';
import { LocalPhoneOutlined, MailOutlined } from '@material-ui/icons/index';
import { connect } from 'react-redux';
import avatarUnknown from '../../../../assets/images/avatarEmisys.png';

class GuestDetailsSide extends Component {
  render() {
    const { user, guest } = this.props;
    return (
      <div
        className="col-3"
        style={{
          backgroundColor: '#F8F9FA',
          textAlign: 'center',
          paddingLeft: '24px',
        }}
      >
        <Avatar
          alt="..."
          src={
            user ? (user.picture ? user.picture.original : avatarUnknown) : ''
          }
          className="user-avatar avatarGuest"
        />
        <div className="infos-guest mt-2">
          <h2>{user ? user.fullName : ''}</h2>
          <small
            className="sous-texte-modal-guest"
            style={{ textTransform: 'capitalize' }}
          >
            {guest ? guest.guestType : ''}
          </small>
          {/*
                    <p style={{marginTop: "10px"}}>
                        <Button variant="outlined" color="inherit" style={{minWidth: 0, padding: "6.5px", marginRight: "5px"}}>
                            <EditOutlined />
                        </Button>
                        <Button variant="outlined" color="secondary" style={{minWidth: 0, padding: "6.5px"}}>
                            <DeleteOutlineOutlined />
                        </Button>
                    </p>
                    */}
          <hr />
          {/* <p>Grande Rue aux bois, 165 <br />1420 Braine-l'Alleud</p> */}
          <p>
            <MailOutlined style={{ height: '19px' }} />
            <a
              style={{ verticalAlign: 'super', color: '#0077ff' }}
              href="mailto:"
            >
              {user ? user.email : ''}
            </a>
          </p>
          {user ? (
            <p>
              <LocalPhoneOutlined style={{ height: '19px' }} />
              <span style={{ verticalAlign: 'super' }}>{user.phonenumber}</span>
            </p>
          ) : (
            ''
          )}
          <hr />
          {/*
                    <div>
                    <h3>Historique</h3>
                        <p><b><i>02/08/2018 - 13:56</i></b> <br /><b>Organisateur 1 </b><br />Le supplier a accepté le deal</p>
                        <hr />
                        <p><b><i>02/08/2018 - 13:56</i></b> <br /><b>Organisateur 1 </b><br />Le supplier a accepté le deal</p>
                        <hr />
                        <p><b><i>02/08/2018 - 13:56</i></b> <br /><b>Organisateur 1 </b><br />Le supplier a accepté le deal</p>
                        <hr />
                    </div>
                    */}
          {/*
                    <p>
                        <CreditCardOutlined style={{height: "19px"}} />
                        <span style={{verticalAlign: "super"}}>Cartes actives : </span>
                    </p>
                    <div>
                        <Chip label="ZYMEFT" variant="outlined" style={{marginRight: "5px", color: "#0077ff", borderColor: "#0077ff"}} />
                        <Chip style={{marginRight: "5px", color: "#0077ff", borderColor: "#0077ff"}} label="AAFGBV" variant="outlined" />
                    </div>
                    */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idProject: state.sdk.user.currentProject.idCurrentProject,
  ticketRequests: state.sdk.ticketRequests,
});

export default connect(mapStateToProps)(GuestDetailsSide);
