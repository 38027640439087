import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { getTicketRequest } from '../../../config/actions.config';
import IntlMessages from '../../../util/IntlMessages';

class AssignTicketsItemAdd extends Component {
  constructor() {
    super();
    this.state = {
      product: '',
      productLabel: '',
      quantity: 1,
    };
  }

  handleChange = (event) => {
    this.setState({ quantity: parseInt(event.target.value) });
  };

  handleChangeTickets = (event) => {
    this.setState({ product: event.value, productLabel: event.label });
    this.getDetailTicket(event.value);
    const product = {
      product: event.value,
      quantity: this.state.quantity,
      productLabel: event.label,
    };
    this.props.addChangeTicket(product, this.props.keyItems);
    this.setState({ product: '', productLabel: '', quantity: 1 });
  };

  onClickAdd = () => {
    const product = {
      product: this.state.product,
      quantity: this.state.quantity,
      productLabel: this.state.productLabel,
    };
    this.props.addTicket(product);
    this.setState({ product: '', quantity: 2 });
  };

  getDetailTicket = (id) => {
    this.props.getTicketRequest(id);
  };

  render() {
    const detailsTicket = [];
    const products = [];

    if (
      this.props.ticketsList.tickets !== undefined &&
      this.props.ticketsList.tickets !== ''
    ) {
      this.props.ticketsList.tickets.forEach((data, i) => {
        if (data.isPrintAtHome) {
          products[i] = { value: data.id, label: data.name };
        }
      });
    }
    if (
      this.props.ticketRequests.details !== undefined &&
      this.props.ticketRequests.details !== ''
    ) {
      if (this.props.ticketRequests.details[this.state.product] !== undefined) {
        if (this.props.ticketRequests.details[this.state.product].length > 1) {
          this.props.ticketRequests.details[this.state.product].forEach(
            (data, i) => {
              detailsTicket[i] = {
                value: data.article,
                label: data.article,
              };
            }
          );
        } else {
          detailsTicket[0] = {
            value:
              this.props.ticketRequests.details[this.state.product].article,
            label:
              this.props.ticketRequests.details[this.state.product].article,
          };
        }
      }
    }

    return (
      <div className="col-12">
        {this.props.disable ? (
          ''
        ) : (
          <div className="row">
            <div className="col-2">
              <div className="form-group">
                <TextField
                  disabled={this.props.disable}
                  required
                  id="quantity"
                  label="Nb"
                  margin="normal"
                  type="number"
                  inputProps={{ min: '0' }}
                  value={this.state.quantity}
                  onChange={this.handleChange}
                  fullWidth
                />
              </div>
            </div>
            <div className="col-5">
              <TextField
                id="standard-select-currency"
                disabled={this.props.disable}
                select
                onChange={(event) =>
                  this.handleChangeTickets(event.target.value)
                }
                name={this.state.labelTicket}
                style={{ width: '100%', marginTop: 32 + 'px' }}
                helperText={<IntlMessages id="modal.activity.tab.helperText" />}
                margin="normal"
                value={this.state.product}
              >
                <MenuItem disabled={true} value="">
                  <em>
                    <IntlMessages id="modal.activity.tab.menuItem.title" />
                  </em>
                </MenuItem>
                {products.map((option, i) => {
                  return (
                    <MenuItem value={option} key={i}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div className="col-5">
              <div className="row">
                {detailsTicket.map((option, i) =>
                  option.label !== null && option.label !== undefined ? (
                    <div key={i} style={{ marginTop: 35 + 'px' }}>
                      <Chip
                        style={{
                          margin: '5px',
                          color: '#0077ff',
                          borderColor: '#0077ff',
                        }}
                        label={option.label}
                        variant="outlined"
                      />
                    </div>
                  ) : (
                    ''
                  )
                )}
                {/*
                            <div className="col-2" style={{marginTop: 35 + "px"}}>
                                <Button variant="outlined" color="primary" style={{minWidth: 0, padding: "6.5px"}} onClick={() => this.onClickAdd()}>
                                    <IconAddCircle color="primary" />
                                </Button>
                            </div>
                            */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketsList: state.sdk.tickets.ticketsList,
  accessZones: state.sdk.accessZones,
  idProject: state.sdk.user.currentProject.idCurrentProject,
  ticketRequests: state.sdk.ticketRequests,
});

export default connect(mapStateToProps, { getTicketRequest })(
  AssignTicketsItemAdd
);
