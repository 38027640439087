import React, { Component } from 'react';
import OrderTableCell from './GuestDetailsTabsContainerAssignmentTableCell';

let counter = 0;

function createData(name, image, email, status) {
  counter += 1;
  return {
    id: counter,
    name,
    image,
    email,
    status,
  };
}

class AssignmentTable extends Component {
  state = {
    data: [
      createData('Alex Dolgove', '', 'test@test.gmail', 'Completed'),
      createData('Domnic Brown', '', 'test@test.gmail', 'Delayed'),
      createData('Garry Sobars', '', 'test@test.gmail', 'Completed'),
      createData('Stella Johnson', '', 'test@test.gmail', 'Delayed'),
    ],
  };

  render() {
    const { data } = this.state;
    return (
      <div
        className="table-responsive-material col-12"
        style={{ marginTop: '20px', maxHeight: '220px' }}
      >
        <table className="default-table table-unbordered table table-sm table-hover">
          <thead className="th-border-b">
            <tr>
              <th>Noms</th>
              <th>Emails</th>
              <th className="status-cell text-right">Tickets</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((data, i) => (
              <OrderTableCell key={i} data={data} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AssignmentTable;
