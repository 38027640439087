import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import { connect } from 'react-redux';
import {
  postBasketNotesRequest,
  getBasketNotesRequest,
  putNoteRequest,
} from '../../config/actions.config';
import { formatDate } from '../../config/services.config';
import IntlMessages from '../../util/IntlMessages';

class TicketsRequestHistory extends Component {
  constructor() {
    super();
    this.state = {
      openModalAddNote: false,
      content: '',
    };

    this.note = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.newBasketNotes !== prevProps.newBasketNotes) {
      if (this.props.newBasketNotes.loading === false) {
        this.props.getBasketNotesRequest(
          this.props.ticketRequestDetails.basket.id
        );
      }
    }
    if (this.props.updatedNote !== prevProps.updatedNote) {
      if (this.props.updatedNote.loading === false) {
        this.props.getBasketNotesRequest(
          this.props.ticketRequestDetails.basket.id
        );
      }
    }
  }

  submitBasketNote = () => {
    if (this.note) {
      this.note.content = this.state.content;
      this.props.putNoteRequest(this.note.id, this.note);
      this.note = null;
      this.setState({ openModalAddNote: false, content: '' });
    } else {
      this.props.postBasketNotesRequest(
        this.props.ticketRequestDetails.basket.id,
        { content: this.state.content }
      );
      this.setState({ openModalAddNote: false, content: '' });
    }
  };

  handleChange = (e) => {
    if (this.note) {
      this.setState({ content: e.target.value });
    } else {
      this.setState({ content: e.target.value });
    }
  };

  handleUpdateNote = (note) => {
    this.note = note;
    this.setState({ openModalAddNote: true, content: this.note.content });
  };

  cancelBasketNote = () => {
    this.note = null;
    this.setState({ openModalAddNote: false });
  };

  render() {
    const { notes } = this.props.basketNotes;
    const { locale, timezone } = this.props;
    return (
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            {notes && notes !== ''
              ? notes.map((data, i) => {
                  return (
                    <div key={i}>
                      <h5>
                        <i>
                          {formatDate(data.dateUpdated, locale, timezone) +
                            ' - ' +
                            data.userProfile.fullName +
                            ' : '}
                        </i>
                        <span style={{ color: '#0077ff' }}>{data.content}</span>
                        {this.props.self.details.id === data.userProfile.id ? (
                          <Button
                            className="ml-2"
                            variant="outlined"
                            color="primary"
                            style={{
                              minWidth: 0,
                              padding: '6.5px',
                              color: '#0077ff',
                              borderColor: '#0077ff',
                            }}
                            onClick={() => this.handleUpdateNote(data)}
                          >
                            <EditIcon fontSize="small" />
                          </Button>
                        ) : (
                          ''
                        )}
                      </h5>
                      <hr
                        style={{
                          marginBottom: 16 + 'px',
                          borderTop: '1px dashed rgba(0, 0, 0, 0.1)',
                        }}
                      />
                    </div>
                  );
                })
              : ''}
          </div>
          <div className="col-12 mb-5 mt-3">
            <Button
              variant="outlined"
              style={{
                color: '#0077ff',
                borderColor: '#0077ff',
                fontSize: '12px',
                float: 'right',
              }}
              onClick={() => this.setState({ openModalAddNote: true })}
            >
              <IntlMessages id="modal.activity.tab.button.addNote" />
            </Button>
          </div>
          <Dialog
            open={this.state.openModalAddNote}
            onClose={() => this.setState({ openModalAddNote: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <IntlMessages id="modal.activity.tab.title.addNote" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <IntlMessages id="modal.activity.tab.contentText.addNote" />
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="note"
                label={<IntlMessages id="modal.activity.tab.label.addNote" />}
                type="text"
                fullWidth
                value={this.state.content}
                onChange={this.handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.cancelBasketNote} color="primary">
                <IntlMessages id="modal.activity.tab.button.cancel.addNote" />
              </Button>
              <Button
                onClick={this.submitBasketNote}
                variant="contained"
                color="primary"
              >
                <IntlMessages id="modal.activity.tab.button.add.addNote" />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ticketRequestDetails: state.sdk.ticketRequests.details,
  basketNotes: state.sdk.baskets.basketNotes,
  newBasketNotes: state.sdk.baskets.newBasketNotes,
  updatedNote: state.sdk.notes.updatedNote,
  self: state.sdk.user.userSelfDetails,
  locale: state.sdk.settings.locale.locale,
  timezone: state.sdk.project.projectDetails.timezone,
});

export default connect(mapStateToProps, {
  postBasketNotesRequest,
  getBasketNotesRequest,
  putNoteRequest,
})(TicketsRequestHistory);
