import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import { userSignOut } from '../../config/actions.config';
import logoAudience from '../../assets/images/logo-audience-dark.png';
import avatarUnknown from '../../assets/images/avatarEmisys.png';
import IntlMessages from '../../util/IntlMessages';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    dropDownOpen: false,
    fullName: '',
    email: '',
    picturePath: null,
  };

  componentDidMount() {
    if (this.props.user) {
      this.setUserInfo(this.props.user);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setUserInfo(this.props.user);
    }
  }

  setUserInfo = (user) => {
    if (
      user.userSelfDetails.details !== undefined &&
      user.userSelfDetails.details !== ''
    ) {
      this.setState({
        fullName: user.userSelfDetails.details.fullName,
        email: user.userSelfDetails.details.email,
      });
      if (user.userSelfDetails.details.picture !== undefined) {
        this.setState({
          picturePath: user.userSelfDetails.details.picture.original,
        });
      }
    }
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  toggle = () => {
    this.setState({ dropDownOpen: !this.state.dropDownOpen });
  };

  render() {
    const { fullName, email, picturePath } = this.state;

    return (
      <div>
        <div className="background-logo">
          <img
            className="logo-byemisys"
            src={logoAudience}
            alt="logo by emisys"
          />
        </div>
        <div className="user-profile d-flex flex-row align-items-center">
          <Avatar
            alt="user avatar"
            src={picturePath !== null ? picturePath : avatarUnknown}
            className="user-avatar"
          />
          <div className="user-detail">
            <span className="sidebar-welcome">
              <IntlMessages id="sidenav.profile.welcome" />
            </span>
            <h4 className="user-name" onClick={this.handleClick}>
              {fullName !== ' ' ? fullName : email}
              <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
            </h4>
          </div>
          <Menu
            className="user-info"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            PaperProps={{
              style: {
                width: 220,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <MenuItem onClick={this.handleRequestClose}>
              <NavLink to="/app/profile">
                <IntlMessages id="appModule.myProfile" />
              </NavLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.props.userSignOut();
              }}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <IntlMessages id="appModule.logOut" />
              <i className="zmdi zmdi-sign-in icon-alert zmdi-hc-fw" />
            </MenuItem>
          </Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.sdk.settings,
  user: state.sdk.user,
});
export default connect(mapStateToProps, { userSignOut })(UserInfo);
