import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from '../config/constants.config';
import { isIOS, isMobile } from 'react-device-detect';
import {
  getUserSelfDetailsRequest,
  getProjectDetailsRequest,
  userSignOut,
  getProjectsSelfRequest,
  setUserCurrentProjectRequest,
  postErrorInSlackRequest,
  getUserProjectByProjectRequest,
  getLocal,
} from '../config/actions.config';
import { ErrorFallback } from '../config/components.config';
import IntlMessages from './../util/IntlMessages';
import TopNav from '../components/TopNav';
import Sidebar from '../containers/SideNav/index';
import Header from '../components/Header/index';
import { ErrorBoundary } from 'react-error-boundary';
import { version, name as projectName } from '../../package.json';

import GuestList from './routes/Member/Guest/GuestList';
import Guest from './routes/Member';

import Error404 from '../components/Error404';

class App extends React.Component {
  componentDidMount() {
    const locale = localStorage.getItem('locale');
    this.props.getProjectsSelfRequest();

    if (this.props.idProject !== 'null' && this.props.idProject !== null) {
      this.props.getUserSelfDetailsRequest(this.props.idProject);
      this.props.getProjectDetailsRequest(this.props.idProject);
    }
    if (locale) {
      this.props.getLocal({ locale, icon: locale });
    }
    this.checkToken();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectsSelf !== this.props.projectsSelf) {
      if (this.props.projectsSelf.projects) {
        if (this.props.idProject === 'null' || this.props.idProject === null) {
          let id_project = localStorage.getItem('id_project'),
            name_project = localStorage.getItem('name_project');
          if (
            id_project === 'null' ||
            name_project === 'null' ||
            id_project === null ||
            name_project === null
          ) {
            localStorage.setItem(
              'id_project',
              this.props.projectsSelf.projects[0].project.id
            );
            localStorage.setItem(
              'name_project',
              this.props.projectsSelf.projects[0].project.name
            );
            this.props.setUserCurrentProjectRequest(
              this.props.projectsSelf.projects[0].project.id,
              this.props.projectsSelf.projects[0].project.name
            );
          } else if (id_project && name_project) {
            this.props.setUserCurrentProjectRequest(id_project, name_project);
          }
        }
      }
    }
    if (prevProps.idProject !== this.props.idProject) {
      if (this.props.idProject) {
        this.props.getUserSelfDetailsRequest(this.props.idProject);
      }
    }

    if (prevProps.authUser !== this.props.authUser) {
      this.checkToken();
    }

    if (prevProps.user !== this.props.user) {
      if (this.props.user.details && this.props.user.details !== '') {
        this.props.getUserProjectByProjectRequest(
          this.props.idProject,
          this.props.user.details.id
        );
      }
    }
  }

  setEnv = (env) => {
    localStorage.setItem('env', env);
  };

  checkToken = () => {
    const token = localStorage.getItem('token');
    if (token !== null && token !== undefined) {
      const tokenExpiration = jwtDecode(token).exp;
      if (
        tokenExpiration &&
        moment(tokenExpiration * 1000) - moment(Date.now()) < 3600
      ) {
        this.props.userSignOut();
      }
    }

    if (token === null) {
      this.props.userSignOut();
    }
  };

  postErrorInSlack = (error) => {
    if (process.env.REACT_APP_ENV === 'prod') {
      this.props.postErrorInSlackRequest({
        application: projectName,
        version: version,
        fields: {
          project: this.props.idProject,
          user: {
            email: this.props.user.details?.email,
            username: this.props.user.details?.fullName,
          },
          link: window.location.href,
          device: window.clientInformation.appVersion,
        },
        error: error.message,
        callstack: error.stack,
      });
    }
  };

  render() {
    this.setEnv(process.env.REACT_APP_ENV);

    const { match, drawerType, navigationStyle, horizontalNavPosition } =
      this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer';

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <ErrorBoundary
        FallbackComponent={(error) => (
          <ErrorFallback
            error={error.error}
            title={<IntlMessages id="appModule.message.error" />}
            back={<IntlMessages id="extraPages.goHome" />}
          />
        )}
        onError={(error) => this.postErrorInSlack(error)}
      >
        <div className={`app-container ${drawerStyle}`}>
          <Sidebar />
          <div className="app-main-container">
            <div
              className={`app-header ${
                navigationStyle === HORIZONTAL_NAVIGATION
                  ? 'app-header-horizontal'
                  : ''
              }`}
            >
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === ABOVE_THE_HEADER && (
                  <TopNav styleName="app-top-header" />
                )}
              <Header />
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
            </div>

            <main className="app-main-content-wrapper">
              <div className="app-main-content">
                <Switch>
                  <Route path={`${match.url}/index`} component={GuestList} />
                  <Route path={`${match.url}/member`} component={Guest} />

                  <Route component={Error404} />
                </Switch>
              </div>
            </main>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  const { drawerType, navigationStyle, horizontalNavPosition } =
    state.sdk.settings;

  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    user: state.sdk.user.userSelfDetails,
    idProject: state.sdk.user.currentProject.idCurrentProject,
    projectsSelf: state.sdk.project.projectsSelf,
    authUser: state.sdk.auth.authUser,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getUserSelfDetailsRequest,
    getProjectDetailsRequest,
    userSignOut,
    getProjectsSelfRequest,
    setUserCurrentProjectRequest,
    getUserProjectByProjectRequest,
    postErrorInSlackRequest,
    getLocal,
  })(App)
);
