import React from 'react';
import Avatar from '@material-ui/core/Avatar/index';
import Button from '@material-ui/core/Button/index';
import { DeleteOutlineOutlined, EditOutlined } from '@material-ui/icons/index';

class OrderTableCell extends React.Component {
  onOptionMenuSelect = (event) => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ menuState: false });
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      menuState: false,
    };
  }

  render() {
    const { id, email, name, image, status } = this.props.data;
    const statusStyle = status.includes('Completed')
      ? 'text-white bg-success'
      : status.includes('On Hold')
      ? 'bg-amber'
      : status.includes('Delayed')
      ? 'text-white bg-danger'
      : 'text-white bg-grey';
    return (
      <tr tabIndex={-1} key={id}>
        <td>
          <div className="user-profile d-flex flex-row align-items-center">
            <Avatar alt={name} src={image} className="user-avatar" />
            <div className="user-detail">
              <h5 className="user-name">{name} </h5>
            </div>
          </div>
        </td>
        <td>
          <div>{email}</div>
        </td>
        <td className="status-cell text-right">
          <div className={` badge text-uppercase ${statusStyle}`}>{status}</div>
        </td>
        <td className="text-right">
          <Button
            variant="outlined"
            color="inherit"
            style={{ minWidth: 0, padding: '6.5px', marginRight: '5px' }}
          >
            <EditOutlined />
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            style={{ minWidth: 0, padding: '6.5px' }}
          >
            <DeleteOutlineOutlined />
          </Button>
        </td>
      </tr>
    );
  }
}

export default OrderTableCell;
