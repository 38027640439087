import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Guest = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/guest/guest-list`}
        component={asyncComponent(() => import('./Guest/GuestList'))}
      />
      <Route
        path={`${match.url}/guest/companies-list`}
        component={asyncComponent(() => import('./Guest/CompaniesList'))}
      />
      <Route
        path={`${match.url}/accreditation/creation`}
        component={asyncComponent(() => import('./Guest/StepperFormAddGuest'))}
      />
      <Route
        component={asyncComponent(() => import('../../../components/Error404'))}
      />
    </Switch>
  </div>
);

export default Guest;
