import React, {Component} from "react";
import {CircularProgress} from "@material-ui/core";

class LoaderModal extends Component {
    render() {
        const { loading } = this.props;
        return (
            <div className="loadingModal" style={(!loading ? {display: "none"} : {color: ""})}>
                <div align="center" style={{marginTop: "40%"}}>
                    <CircularProgress style={{width: "5%", height: "auto"}} color="white" />
                </div>
            </div>
        );
    }
}

export default LoaderModal;
